import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import { FaFilePdf } from "react-icons/fa"

const Normativa = () => {
  const data = useStaticQuery(graphql`
    query Images_normativa {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const [norm1, setNorm1] = useState(false)
  const Norm1 = () => {
    if (!norm1){setNorm2(false); setNorm3(false); setNorm4(false);}
    setNorm1(!norm1)
    document.getElementById("norm-title").className = ("hover")
    document.getElementById("normativa1").className = ("norm show")
    document.getElementById("normativa2").className = ("norm hide")
    document.getElementById("normativa3").className = ("norm hide")
    document.getElementById("normativa4").className = ("norm hide")
  }
  const [norm2, setNorm2] = useState(false)
  const Norm2 = () => {
    if (!norm2){setNorm1(false); setNorm3(false); setNorm4(false);}
    setNorm2(!norm2)
    document.getElementById("norm-title").className = ("normal")
    document.getElementById("normativa1").className = ("norm hide")
    document.getElementById("normativa2").className = ("norm show")
    document.getElementById("normativa3").className = ("norm hide")
    document.getElementById("normativa4").className = ("norm hide")
  }
  const [norm3, setNorm3] = useState(false)
  const Norm3 = () => {
    if (!norm3){setNorm1(false); setNorm2(false); setNorm4(false);}
    setNorm3(!norm3)
    document.getElementById("norm-title").className = ("normal")
    document.getElementById("normativa1").className = ("norm hide")
    document.getElementById("normativa2").className = ("norm hide")
    document.getElementById("normativa3").className = ("norm show")
    document.getElementById("normativa4").className = ("norm hide")
  }
  const [norm4, setNorm4] = useState(false)
  const Norm4 = () => {
    if (!norm4){setNorm4(false); setNorm2(false); setNorm3(false);}
    setNorm4(!norm4)
    document.getElementById("norm-title").className = ("normal")
    document.getElementById("normativa1").className = ("norm hide")
    document.getElementById("normativa2").className = ("norm hide")
    document.getElementById("normativa3").className = ("norm hide")
    document.getElementById("normativa4").className = ("norm show")
  }
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Código Ético | Qualoom</title>
        <meta name="title" content="Código Ético | Qualoom"/>
        <meta name="description" content="Ética empresarial en Qualoom Expertise Technology: valores de la empresa, principios éticos, responsabilidad social y transparencia."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/normativa-conducta/"/>
        <link rel="canonical" href="https://www.qualoom.es/normativa-conducta/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/normativa-conducta/"/>
        <meta property="og:title" content="Código Ético | Qualoom"/>
        <meta property="og:description" content="Ética empresarial en Qualoom Expertise Technology: valores de la empresa, principios éticos, responsabilidad social y transparencia."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/normativa-conducta/"/>
        <meta property="twitter:title" content="Código Ético | Qualoom"/>
        <meta property="twitter:description" content="Ética empresarial en Qualoom Expertise Technology: valores de la empresa, principios éticos, responsabilidad social y transparencia."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/normativa-conducta/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Código Ético", "item": {"@id": "https://www.qualoom.es/normativa-conducta/", "name": "Código Ético"}},
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Código Ético</h1>
              <p>Este código establece los principios éticos que guían nuestras acciones, promoviendo un ambiente laboral seguro, la igualdad de oportunidades y la responsabilidad social. Cumplimos con todas las leyes y regulaciones aplicables, fomentando relaciones comerciales justas y sostenibles.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Descubre nuestro Código Ético</h2><hr></hr>
          <p>En Qualoom, estamos comprometidos con la más alta ética empresarial y creemos firmemente en la importancia de la responsabilidad social y el comportamiento ético en todas nuestras operaciones. Este Código Ético establece los valores y principios fundamentales que guían nuestra conducta y define nuestras expectativas en términos de comportamiento ético para nuestros empleados y colaboradores.</p><br/>
          <a href="/documents/partner-program/Qualoom Expertise Technology - Partner Program. Código ético.pdf" target="_blank" id="codigo">Quiero descargar el Código ético<FaFilePdf size={20}/></a><br/>
          <div className="normativa-menu">
            <span onClick={Norm1} onKeyDown aria-hidden className="hover" id="norm-title">Valores de la empresa</span>
            <span onClick={Norm2} onKeyDown aria-hidden className={norm2? "hover" : "normal"}>Principios éticos</span>
            <span onClick={Norm3} onKeyDown aria-hidden className={norm3? "hover" : "normal"}>Conducta laboral</span>
            <span onClick={Norm4} onKeyDown aria-hidden className={norm4? "hover" : "normal"}>Responsabilidad social</span>
          </div>
          <div className="norm" id="normativa1">
            <h3>Valores de la Empresa</h3>
            <div className="norm-item"><div><h4>Integridad</h4><p>Actuamos con honestidad, transparencia y coherencia en todas nuestras interacciones internas y externas</p></div></div>
            <div className="norm-item"><div><h4>Respeto</h4><p>Tratamos a todos con respeto, dignidad y equidad, sin importar su origen, raza, género, religión o cualquier otra característica personal</p></div></div>
            <div className="norm-item"><div><h4>Responsabilidad</h4><p>Asumimos la responsabilidad de nuestras acciones y nos esforzamos por cumplir nuestras obligaciones con todas las partes interesadas</p></div></div>
          </div>
          <div className={norm2? "norm show" : "norm hide"} id="normativa2">
            <h3>Principios Éticos</h3>
            <div className="norm-item"><div><h4>Honestidad</h4><p>Nos comprometemos a actuar con sinceridad y a ser veraces en todas nuestras comunicaciones y transacciones</p></div></div>
            <div className="norm-item"><div><h4>Confidencialidad</h4><p>Respetamos la confidencialidad de la información confidencial de la empresa y de nuestros clientes</p></div></div>
            <div className="norm-item"><div><h4>Igualdad de Oportunidades</h4><p>Ofrecemos igualdad de oportunidades para el desarrollo personal y profesional, sin ningún tipo de discriminación</p></div></div>
          </div>
          <div className={norm3? "norm show" : "norm hide"} id="normativa3">
            <h3>Conducta Laboral</h3>
            <div className="norm-item"><div><h4>Ambiente de Trabajo Seguro</h4><p>Mantenemos un entorno laboral seguro y saludable para todos los empleados, cumpliendo con las normativas de seguridad y salud ocupacional</p></div></div>
            <div className="norm-item"><div><h4>No Discriminación</h4><p>No toleramos la discriminación, el acoso de ningún tipo o el trato injusto hacia ningún empleado, cliente o colaborador dentro de la empresa</p></div></div>
            <div className="norm-item"><div><h4>Cumplimiento de las Leyes Laborales</h4><p>Cumplimos con todas las leyes laborales aplicables en las ubicaciones donde operamos y respetamos los derechos laborales fundamentales</p></div></div>
          </div>
          <div className={norm4? "norm show" : "norm hide"} id="normativa4">
            <h3>Responsabilidad Social</h3>
            <div className="norm-item"><div><h4>Sostenibilidad Ambiental</h4><p>Promovemos prácticas comerciales sostenibles y nos esforzamos por minimizar nuestro impacto en el medio ambiente</p></div></div>
            <div className="norm-item"><div><h4>Inclusión y Diversidad</h4><p>Promovemos activamente la inclusión y la diversidad en nuestra empresa, creando un entorno de trabajo que respeta y valora las diferencias individuales</p></div></div>
            <div className="norm-item"><div><h4>Ética en las Relaciones Comerciales</h4><p>Mantenemos relaciones comerciales justas y éticas con nuestros proveedores, clientes y otros socios comerciales</p></div></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Normativa
